<!--
 * @Description: 补贴扣扣详情
 * @Author: 琢磨先生
 * @Date: 2023-01-15 12:37:28
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-20 17:03:48
-->
<template>
    <el-dialog
      title="补贴扣扣详情"
      v-model="dialogVisible"
      width="700px"
      :close-on-click-modal="false"
      @closed="onClosed"
      draggable
    >
      <div class="wrp" v-loading="loading">
        <el-descriptions :column="2">
          <el-descriptions-item label="类型">{{model.type_text}}</el-descriptions-item>
          <el-descriptions-item label="项目">{{model.expense_item_name}}</el-descriptions-item>
          <el-descriptions-item label="金额(元)">
            <b>￥{{model.decimal_amount}}</b>
          </el-descriptions-item>
          <el-descriptions-item label="日期">{{model.date}}</el-descriptions-item>
          <el-descriptions-item label="备注">{{model.remark}}</el-descriptions-item>
          <el-descriptions-item label="创建人">{{model.emp?.chs_name}}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{model.create_at}}</el-descriptions-item>
          <el-descriptions-item label="删除状态"  v-if="model.is_deleted">
                <el-tag type="danger" size="small">已删除</el-tag>{{ model.deleted_at }}
        </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogVisible: false,
        loading: false,
        model: {},
      };
    },
    props: ["id"],
    emits: ["close"],
    watch: {
      id: {
        handler() {
          if (this.id) {
            this.dialogVisible = true;
            this.loadData();
          }
        },
        immediate: true,
      },
    },
    methods: {
      onClosed() {
        this.$emit("close");
      },
  
      /**
       * 加载详情
       */
      loadData() {
        this.loading = true;
        this.$http
          .get("seller/v1/Subsidy/dtl?id=" + this.id)
          .then((res) => {
            if (res.code == 0) {
              this.model = res.data;
            }
          })
          .finally(() => (this.loading = false));
      },
    },
  };
  </script>
  
  <style   scoped>
  .wrp {
    min-height: 400px;
  }
  </style>